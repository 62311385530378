import React, { useState, useRef } from 'react';
import axios from 'axios';
import Select from 'react-select';

const FormBuilder = (props) => {
  const {
    form: initialForm = {
      id: null,
      name: '',
      send_notifications: false,
      requires_privacy: false,
      contacts: [],
      form_type: { value: 'standard', label: 'Standard' },
      questions: [
        {
          id: null,
          text: '',
          type: 'TextQuestion',
          removable: true,
          options: [],
        },
        {
          id: null,
          text: 'Does this form require attention?',
          type: 'AttentionRequiredQuestion',
          removable: false,
          required: true,
          generated: true,
          options: [
            { id: null, name: 'Yes' },
            { id: null, name: 'No' },
          ],
        },
      ],
    },
    editing = false,
    hasFacilities = false,
    users = [],
    form_types = [],
  } = props;

  const [formState, setFormState] = useState({
    editing,
    isSaving: false,
    hasFacilities,
    users,
    form_types,
    form: initialForm,
  });

  const editorRefs = useRef([]);

  const handleNameChange = (event) => {
    setFormState({
      ...formState,
      form: {
        ...formState.form,
        name: event.target.value,
      },
    });
  };

  // Many more functions to make all unchanged class methods into the function methods
  // Goes here ...

  // Now rendering parts
  return (
    <div className='form form-builder'>
      <div className='row valign-wrapper'>
        <div className='col s3'>
          <h2 className='right'>Form Name:</h2>
        </div>
        <div className='col s8'>
          <input
            type='text'
            disabled={!editing}
            placeholder='Form Name'
            onChange={this.handleNameChange}
            value={name}
          />
          {errors && errors.name && errors.name.length && (
            <span className='invalid'>{errors.name}</span>
          )}
        </div>
        <div className='col s1 right-align'>
          {!editing && (
            <button className='btn edit-action' onClick={this.cycleEditing}>
              Edit
            </button>
          )}
        </div>
      </div>

      {this.renderFormTypeSection()}

      {this.renderRequiresPrivacySection()}

      {this.renderContactSection()}

      {this.renderQuestions(
        questions.filter(
          (q) => !q._destroy && q.type !== 'AttentionRequiredQuestion'
        )
      )}

      {editing && (
        <div className='add-question col s12 center-align'>
          <button
            className='btn'
            onClick={() => {
              this.addQuestion(questions.length - 1);
            }}
          >
            <span className='add-question-text'>
              <i className='material-icons left'>add</i>
              Add Question Here
            </span>
          </button>
        </div>
      )}

      {editing && (
        <div className='form-actions row'>
          <div className='col s12 right-align'>
            <button
              className='btn btn-large cancel'
              onClick={this.handleCancel}
            >
              Cancel
            </button>
            <button
              className='btn btn-large save'
              disabled={isSaving}
              onClick={this.handleSave}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormBuilder;
