import React, { Component } from 'react';
import { Avatar, Tooltip, Input } from 'antd';
import { Comment } from '@ant-design/compatible';

//import 'antd/dist/antd.css'

const { TextArea } = Input;

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMessage: '',
      conversationProxy: props.conversationProxy,
      messages: [
        {
          time: new Date().toLocaleString(),
          content: `
            We supply a series of design principles, practical patterns and high quality design
            resources (Sketch and Axure), to help people create their product prototypes beautifully
            and efficiently.
          `,
        },
      ],
      loadingState: 'initializing',
    };
  }

  // loadMessagesFor = (thisConversation) => {
  //   if (this.state.conversationProxy === thisConversation) {
  //       thisConversation.getMessages()
  //           .then(messagePaginator => {
  //               if (this.state.conversationProxy === thisConversation) {
  //                   this.setState({ messages: messagePaginator.items, loadingState: 'ready' });
  //               }
  //           })
  //           .catch(err => {
  //               console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
  //               this.setState({ loadingState: "failed" });
  //           });
  //   }
  // };

  // componentDidMount = () => {
  //     if (this.state.conversationProxy) {
  //       this.loadMessagesFor(this.state.conversationProxy);

  //       if (!this.state.boundConversations.has(this.state.conversationProxy)) {
  //           let newConversation = this.state.conversationProxy;
  //           newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
  //           this.setState({boundConversations: new Set([...this.state.boundConversations, newConversation])});
  //       }
  //     }
  // }

  // componentDidUpdate = (oldProps, oldState) => {
  //   if (this.state.conversationProxy !== oldState.conversationProxy) {
  //       this.loadMessagesFor(this.state.conversationProxy);

  //       if (!this.state.boundConversations.has(this.state.conversationProxy)) {
  //           let newConversation = this.state.conversationProxy;
  //           newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
  //           this.setState({boundConversations: new Set([...this.state.boundConversations, newConversation])});
  //       }
  //   }
  // };

  // static getDerivedStateFromProps(newProps, oldState) {
  //   let logic = (oldState.loadingState === 'initializing') || oldState.conversationProxy !== newProps.conversationProxy;
  //   if (logic) {
  //     return { loadingState: 'loading messages', conversationProxy: newProps.conversationProxy };
  //   } else {
  //     return null;
  //   }
  // }

  // messageAdded = (message, targetConversation) => {
  //   if (targetConversation === this.state.conversationProxy)
  //       this.setState((prevState, props) => ({
  //           messages: [...prevState.messages, message]
  //       }));
  // };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  sendMessage = (event) => {
    event.preventDefault();
    const message = this.state.newMessage;
    this.setState({
      newMessage: '',
      messages: [
        ...this.state.messages,
        {
          time: new Date().toLocaleString(),
          content: message,
        },
      ],
    });
    // this.state.conversationProxy.sendMessage(message);
  };

  renderMessage = (message) => {
    return (
      <Comment
        key={message.time}
        author={window.env.user.full_name}
        avatar={<Avatar>{window.env.user.initials}</Avatar>}
        content={message.content}
        datetime={
          <Tooltip title={message.time}>
            <span>{message.time}</span>
          </Tooltip>
        }
      />
    );
  };

  onMessageChange = ({ target: { value } }) => {
    this.setState({ newMessage: value });
  };

  render = () => {
    const { newMessage, messages } = this.state;

    return (
      <div
        className='chat-window'
        style={{
          paddingLeft: '1em',
          position: 'absolute',
          height: '80%',
          width: '70%',
        }}
      >
        <div className='messages' style={{ height: '90%', overflow: 'scroll' }}>
          <div className='message'>
            {messages.map(this.renderMessage)}
            <div
              style={{ float: 'left', clear: 'both' }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
          </div>
        </div>
        <div style={{ display: 'flex', position: 'relative' }}>
          <div style={{ flexGrow: 1, display: '' }}>
            <TextArea
              onPressEnter={this.sendMessage}
              value={newMessage}
              onChange={this.onMessageChange}
              showCount
              autoSize
            />
          </div>
        </div>
      </div>
    );
  };
}

export default Conversation;
