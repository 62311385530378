import React from 'react'
import { Picky } from 'react-picky'

import 'react-picky/dist/picky.css'

export default (props) => (
  <Picky
    {...props}
    multiple={true}
    includeSelectAll={true}
    includeFilter={true}
    valueKey="value"
    labelKey="label"
    numberDisplayed={0}
    selectAllText={props.options.length == props.value.length ? "Deselect All" : "Select All" }
    placeholder={`${props.valueType}: none selected`}
    manySelectedPlaceholder={`${props.valueType}: %s selected`}
    allSelectedPlaceholder={`${props.valueType}: %s selected`}
    render={({
      style,
      isSelected,
      item,
      selectValue,
      labelKey,
      valueKey,
      multiple,
    }) => {
      return (
        <li
          style={style} // required
          key={item[valueKey]} // required
          onClick={() => selectValue(item)}
        >
          {/* required to select item */}
          <input type="checkbox" checked={isSelected} readOnly />
          <span className='checkbox-select__item'>
            { isSelected && <span className='checkbox-select__item--icon'><i className='material-icons'>check</i></span>}
            <span className='checkbox-select__item--key'>{item[labelKey]}</span>
          </span>
        </li>
      )
    }}
  />
)
