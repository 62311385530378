import React from "react"
import { RingProgress } from '@ant-design/plots';

export default (props) => {
  let config = {
    height: 200,
    width: 200,
    autoFit: false,
    percent: props.percent
  }

  if (props.percent == 1) {
    config.color = ['#28c213', '#E8EDF3']
  } else if (props.percent >= .5) {
    config.color = ['#EED202', '#E8EDF3']
  } else {
    config.color = ['#cc0000', '#E8EDF3']
  }

  return (
    <div className="center" style={{ }}>
      <h3>{props.name}</h3>
      <RingProgress {...config} />
    </div>
  )
}