import React, { useRef } from 'react';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

export const MdEditor = () => {
  const editorRef = useRef();

  return (
    <>
      <Editor
        previewStyle="vertical"
        height="400px"
        initialEditType="wysiwyg"
        initialValue="Diff"
        ref={editorRef}
        hideModeSwitch={true}
      />
    </>
  );
};
