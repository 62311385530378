import React from "react"
import "../../../../assets/stylesheets/components/_widget-header.scss"

const Header = React.forwardRef(({ setModal, title, pastDue = 0, approveAll = false, confirmAll = false }, ref) => {
  return (
<div className="container-header" ref={ref}>
  <div className="title-container">
    <h2 className="widget-title" style={{ marginBottom: '0' }}>
      {title}
    </h2>

    {pastDue > 0 &&
      <h2 className="past-due-title" style={{ marginBottom: '0' }}>
        {pastDue} - PAST DUE
      </h2>
    }
  </div>

  {approveAll &&
    <div className="button-container">
      <a onClick={() => setModal(true)} className="approve-all" data-toggle="head-count-modal" data-target="head-count-modal" data-remote="true" href="/complete_all_head_count_modal">
        <span>Approve All</span>
      </a>
    </div>
    }
    {
     confirmAll &&
      <div className="button-container">
        <a onClick={() => setModal(true)} className="approve-all" data-confirm="Are you sure you want to confirm all dispensations as missed?" href="/confirm_all_dispensations">
          <span className="confirm-button">Confirm All Missed</span>
        </a>
      </div>
    }
</div>
  )
}
);

export default Header;
