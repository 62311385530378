import React from 'react'
import { Column } from '@ant-design/plots'

export default (props) => {
  const data = [
    {
      type: '0100',
      sales: 38,
    },
    {
      type: '0200',
      sales: 52,
    },
    {
      type: '0300',
      sales: 61,
    },
    {
      type: '0400',
      sales: 145,
    },
    {
      type: '0500',
      sales: 189,
    },
    {
      type: '0600',
      sales: 123,
    },
    {
      type: '0700',
      sales: 190,
    },
    {
      type: '0800',
      sales: 212,
    },
  ];
  const config = {
    data,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      sales: {
        alias: 'count',
      },
    },
  }

  return (
    <div className="center" style={{ width: '500px' }}>
      <h3>Activity Breakdown by Hour</h3>
      <Column {...config} />
    </div>
  )
}