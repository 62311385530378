import React, { useLayoutEffect, useRef, useState } from "react";
import { Table } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import TimeTag from "../../TimeTag";
import useChannel from "./reload";



const mapFunction = (item, index) => {
  return {
    key: (index + 1).toString(),
    inmate: item.inmate_name,
    cell: item.cell_name,
    facility: item.facility_name,
    pastdue: {color: item.color, location_name: item.location_name, inmate_not_present: item.inmate_not_present, last_observed_at: item.last_observed_at, next_observed_at: item.next_observed_at },
    color: item.color,
    inmate_path: item.inmate_path,
    inmate_not_present: item.inmate_not_present,
    location_name: item.location_name,
  };
};

const SpecialNeedsWatch = () => {
  const columns = [
    {
      title: 'Inmate',
      dataIndex: 'inmate',
      key: 'inmate',
      render: (text, record) => <a href={record.inmate_path} >{text}</a>,
    },
    {
      title: 'Facility',
      dataIndex: 'facility',
      key: 'facility',
    },
    {
      title: 'Cell',
      dataIndex: 'cell',
      key: 'cell',
    },
    {
      title: 'Time Past Due',
      dataIndex: 'pastdue',
      key: 'pastdue',
      render: (record) => <TimeTag
                            color={record.color}
                            location_name={record.location_name}
                            inmate_not_present={record.inmate_not_present}
                            time={record.last_observed_at ? record.next_observed_at : "Not Yet Observed"}
                          />
    },
  ];
  const { tableData, title, pastDueCount, fetchData } = useTableData("/dashboard/special_needs_watch.json", mapFunction, "special_needs_inmates");
  useChannel("InmateObservationUpdateChannel", fetchData);

  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#special_needs .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  return (
    <div id="special_needs">
      <Header title={title} pastDue={pastDueCount} ref={elementRef} />
      <Table
        rowClassName={(record) => {
          return record.inmate_not_present ? "gray" :
          !record.inmate_not_present && record.color === "red" ? "past-due-red" :
          !record.inmate_not_present && record.color === "yellow"? "past-due-yellow" :
          "past-due-red"}
        }
        dataSource={tableData}
        columns={columns}
        pagination={false}
      />
    </div>
  )
}

export default SpecialNeedsWatch;
