import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import TimeTag from "../../TimeTag";
import useChannel from "./reload";



const mapFunction = (item, index) => {
  return {
    key: (index + 1).toString(),
    cell: item.cell_name,
    facility: item.display_name,
    pastdue: {
      time: item.next_observed_at, color: item.color
    },
    color: item.color,
  };
};

const CellObservations = () => {

  const columns = [
    {
      title: 'Facility',
      dataIndex: 'facility',
      key: 'facility',
    },
    {
      title: 'Cell',
      dataIndex: 'cell',
      key: 'cell',
    },
    {
      title: 'Time Past Due',
      dataIndex: 'pastdue',
      key: 'pastdue',
      render: (record) => <TimeTag time={record.time} color={ record.color } />
    },
  ];

  const { tableData, title, pastDueCount, fetchData } = useTableData("/dashboard/cell_observations.json", mapFunction, "cells");
  useChannel("CellObservationUpdateChannel", fetchData)
  // useLogAPI("/dashboard/cell_observations.json")

  const elementRef = useRef(null);

  useLayoutEffect(() => {

    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#cell_observation .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);


  return (
    <div id="cell_observation">
      <Header title={title} pastDue={pastDueCount} ref={ elementRef } />
      <Table
        rowClassName={(record) =>{
          return record.color === "red" ? "past-due-red" :
          record.color === "yellow"  ? "past-due-yellow" :
          "past-due-red"}
        }
        dataSource={tableData}
        columns={columns}
        pagination={false}
      />
    </div>
  );
}

export default CellObservations;
