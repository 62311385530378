import { useEffect } from "react"

const useChannel = (channel, reload) => {
    useEffect(() => {
        const subscription = App.cable.subscriptions.create(
            { channel },
            {
                connected: () => { },
                received: () => {
                    console.log("reload for channel " + channel)
                    reload()
                },
                disconnected: () => {
                    console.log('disconnected')
                },
                rejected: () => {
                    console.log('rejected')
                }
            })
        return () => {
            console.log("cleanup for channel " + channel)
            subscription.unsubscribe()

        }
    }, [reload])
}
export default useChannel
