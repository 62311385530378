import React, { useLayoutEffect, useRef } from "react";
import { Table } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import { formatDate } from '../../../utils/date_formater';



const mapFunction = (item, index) => {
  return {
    key: (index + 1).toString(),
    inmate: item.full_name,
    round: item.round_name,
    prescription_name: item.prescription_name,
    due_date: formatDate(item.due_date),
    quantity: item.quantity,
    prn: item.prn,
    add_note: item.add_note,
  };
};

const MissedDispensation = ({ setModal }) => {

  const columns = [
    {
      title: 'Inmate',
      dataIndex: 'inmate',
      key: 'inmate',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Round',
      dataIndex: 'round',
      key: 'round',
    },
    {
      title: 'Name',
      dataIndex: 'prescription_name',
      key: 'prescription_name',
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'PRN',
      dataIndex: 'prn',
      key: 'prn',
    },
    {
      title: '',
      dataIndex: 'add_note',
      key: 'add_note',
      render: () => <a onClick={ ()=> setModal(true) } className="table-action" data-toggle="dispensation-modal" data-target="dispensation-modal" data-remote="true" href="/dashboard/dispensations">
                      <i className="material-icons">add</i>
                      <span>Add Note</span>
                    </a>,
    },
  ];

  const { tableData, title, pastDueCount, fetchData } = useTableData("/dashboard/dispensations.json", mapFunction, "lines", "Missed Dispensations");

  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#missed_dispensation .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  return (
    <div id="missed_dispensation">
      <Header title={ title } pastDue={ pastDueCount } ref={ elementRef } confirmAll={ true } setModal={ setModal } />
      <Table
        rowClassName={ (record) => record.color === "red" ? "past-due" : "" }
        dataSource={ tableData }
        columns={ columns }
        pagination={ false }
      />
    </div>
  )
}

export default MissedDispensation;
