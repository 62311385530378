import React from 'react';
import { Pie } from '@ant-design/plots';

export default (props) => {
  const data = [
    {
      type: 'Officer Bob',
      value: 27,
    },
    {
      type: 'Officer Cindy',
      value: 25,
    },
    {
      type: 'Officer Jerry',
      value: 18,
    },
    {
      type: 'Officer John',
      value: 15,
    },
    {
      type: 'Officer Randy',
      value: 10,
    },
    {
      type: 'Officer Sheila',
      value: 5,
    },
  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    label: null,
    label: {
      type: 'inner',
      content: '{name} - {value}',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  }

  return (
    <div className="center" style={{ width: '500px' }}>
      <h3>Activity Breakdown by Officer</h3>
      <Pie {...config} />
    </div>
  )
}