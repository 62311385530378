import React, { useLayoutEffect, useRef } from "react";
import { Table } from 'antd';
import Header from './header';
import '../../../../assets/stylesheets/components/_widget.scss';
import useTableData from '../../../hooks/useTableData.js';
import { formatDateWithTime } from "../../../utils/date_formater";
import useChannel from "./reload";

const columns = [
  {
    title: 'Inmate',
    dataIndex: 'inmate',
    key: 'inmate',
    render: (text, record)  => <a href={ record.inmate_path }>{text}</a>,
  },
  {
    title: 'Inmate',
    dataIndex: 'separate_inmate',
    key: 'inmate',
    render: (text, record) => <a href={ record.keep_separate_path }>{text}</a>,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
  },
];

const mapFunction = (item, index) => {
  return {
    key: (index + 1).toString(),
    inmate: item.inmate_full_name,
    inmate_path: item.inmate_path,
    separate_inmate: item.keep_separate_full_name,
    keep_separate_path: item.keep_separate_path,
    location: item.location_name,
    time: formatDateWithTime(item.at),
  };
};

const KeepSeparates = () => {
  const { tableData, title, pastDueCount, fetchData } = useTableData("/dashboard/keep_separates.json", mapFunction, "alerts", "Keep Separates Alert");
  useChannel("KeepSeparateAlertUpdateChannel", fetchData)

  const elementRef = useRef(null);

  useLayoutEffect(() => {
    const calcHeight = () => {
      const refheight = elementRef.current.offsetHeight;
      const thead = document.querySelector('#keep_separate .ant-table-thead');
      if (thead) thead.style.top = `${refheight}px`;
    };

    const observer = new MutationObserver(calcHeight);
    observer.observe(elementRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    const handleResize = () => {
      calcHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);
  return (
    <div id="keep_separate">
      <Header title={title} pastDue={pastDueCount} ref={ elementRef }/>
      <Table
        rowClassName={"past-due-red"}
        dataSource={tableData}
        columns={columns}
        pagination={false}
      />
    </div>
  )
}

export default KeepSeparates;
