import React from 'react'
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  useFormikContext,
  useField,
  useFormik,
} from 'formik'

export function Form(props) {
  return (
    <Formik {...props}>
      <FormikForm className='needs-validation'noValidate=''>
        {props.children}
      </FormikForm>
    </Formik>
  )
}

export function TextField(props) {
  const { name , label, placeholder, ...rest } = props

  return (
    <>
      { label && <label htmlFor={name}>{label}</label> }
      <Field
        className='form-control'
        type='text'
        name={name}
        id={name}
        placeholder={placeholder || ''}
        {...rest}
      />
      <ErrorMessage name={name} render={msg => <div style={{color: red}}>{msg}</div>} />
    </>
  )
}

export function SignatureField(props) {
  const { name , label, placeholder, ...rest } = props

  return (
    <>
      { label && <label htmlFor={name}>{label}</label> }
      <Field
        className='form-control'
        type='text'
        name={name}
        id={name}
        placeholder={placeholder || ''}
        {...rest}
      />
      <ErrorMessage name={name} render={msg => <div style={{color: red}}>{msg}</div>} />
    </>
  )
}



export function SelectField(props) {
  const { name, label, options } = props

  return (
    <>
      { label && <label htmlFor={name}>{label}</label> }
      <Field
        as='select'
        className='browser-default'
        id={name}
        name={name}
      >
        <option value=''>Choose...</option>
        {options.map((option, index) => <option value={option.value} label={option.label || option.value} />)}
      </Field>
      <ErrorMessage name={name} render={msg => <div style={{color: red}}>{msg}</div>} />
    </>
  )
}

export function MultiSelectField(props) {
  const { name, label, options } = props

  return (
    <>
      { label && <label htmlFor={name}>{label}</label> }
      {options.map((option, index) => (
        <p>
          <label>
            <Field
              as='input'
              className='checkbox'
            />
            <span>{option.value}</span>
          </label>
        </p>
      ))}
    </>
  )
}

export function SubmitButton(props) {
  const { title, ...rest } = props
  const { isSubmitting } = useFormikContext()

  return (
    <button type='submit' {...rest} disabled={isSubmitting}>{title}</button>
  )
}
