import React, { useState, useEffect, useRef } from "react"
// import LiveEvents from './LiveEvents'
import { SettingFilled } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import axios from 'axios'

// Widgets
import { Responsive } from "react-grid-layout"
import CellObservationsWidget from './shared/widgets/cell_observations'
import SpecialNeedsWatch from './shared/widgets/special_needs_watch'
import KeepSeparateAlertsWidget from './shared/widgets/keep_separates'
import HeadCountAlertsWidget from './shared/widgets/head_count_alerts'
import MissedDispensation from './shared/widgets/missed_dispensations'
import LiveWidget from './shared/widgets/live'
import MealPass from "./shared/widgets/meal_pass"
import ResizeObserver from 'resize-observer-polyfill';


// Styles
import 'react-grid-layout/css/styles.css'
import '../../assets/stylesheets/components/_dashboard.scss'

const LAYOUT = {
  xl: [
    { w: 5, h: 3, x: 7, y: 0, i: "a", minW: 3, minH: 2 },
    { w: 4, h: 3, x: 12, y: 0, i: "b", minW: 3, minH: 2 },
    { w: 7, h: 3, x: 0, y: 0, i: "c", minW: 3, minH: 2 },
    { w: 4, h: 3, x: 12, y: 3, i: "d", minW: 3, minH: 2 },
    { w: 6, h: 3, x: 0, y: 3, i: "e", minW: 6, minH: 2 },
    { w: 16, h: 3, x: 0, y: 6, i: "f", minW: 3, minH: 2 },
    { w: 6, h: 3, x: 6, y: 3, i: "g", minW: 6, minH: 2 }
  ],
  lg: [
    {w: 6, h: 3, x: 6, y: 0, i: 'a', minH: 2, minW: 3},
    {w: 4, h: 3, x: 12, y: 0, i: 'b', minH: 2, minW: 3},
    {w: 6, h: 3, x: 0, y: 0, i: 'c', minH: 2, minW: 3},
    {w: 4, h: 3, x: 12, y: 3, i: 'd', minH: 2, minW: 3},
    {w: 6, h: 3, x: 0, y: 3, i: 'e', minH: 2, minW: 6},
    {w: 16, h: 3, x: 0, y: 6, i: 'f', minH: 2, minW: 3},
    { w: 6, h: 3, x: 6, y: 3, i: 'g', minH: 2, minW: 6}
  ],
  md: [
    { i: "a", x: 0, y: 0, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "b", x: 4, y: 0, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "c", x: 0, y: 1, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "d", x: 4, y: 1, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "e", x: 0, y: 2, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "f", x: 4, y: 2, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "g", x: 0, y: 1, w: 4, h: 4, minH: 2, minW: 4 }
  ],
  sm: [
    { i: "a", x: 0, y: 0, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "b", x: 0, y: 1, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "c", x: 0, y: 2, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "d", x: 0, y: 3, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "e", x: 0, y: 4, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "f", x: 0, y: 5, w: 4, h: 4, minH: 2, minW: 4 },
    { i: "g", x: 0, y: 6, w: 4, h: 4, minH: 2, minW: 4 }
  ]
}

const Dashboard = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [events, setEvents] = useState([]);
  const [layouts, setLayouts] = useState({ ...LAYOUT });
  const [initialLayouts, setInitialLayouts] = useState({ ...LAYOUT });
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmedAction, setConfirmedAction] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [headCountModal, setHeadCountModal] = useState(false);
  const [dispensationModal, setDispensationModal] = useState(false);
  const [mealPassModal, setMealPassModal] = useState(false);
  const [mealPassCompleteModal, setMealPassCompleteModal] = useState(false);
  const [dashboardWidth, setDashboardWidth] = useState(window.innerWidth);
  const containerRef = useRef(null);

  const openModal = (action, message) => {
    setConfirmedAction(() => action);
    setModalMessage(message);
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
    confirmedAction();
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const widgets = document.querySelectorAll('.widget');
    if (isEditing) {
      widgets.forEach((widget) => {
        widget.scrollTop = 0
      });
    }
  }, [isEditing])

  useEffect(() => {
    if (props.events) setEvents(props.events);

    try {
      axios.get("/user_configuration/dashboard_grid_layout").then(({ data }) => {
        setLayouts( data.layout );
        setInitialLayouts(data.layout);
        // console.log({actual:layouts})
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
        const ro = new ResizeObserver(entries => {
            for (let entry of entries) {
              const { width } = entry.contentRect;
              setDashboardWidth(width);
            }
        });
        ro.observe(containerRef.current);
        return () => ro.disconnect();
    }
  }, []);




  const enableEditing = () => {

    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setLayouts(initialLayouts);
  }

  const saveLayout = () => {
    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      axios.post('/user_configuration/dashboard_grid_layout', { layout: layouts }, {
        headers: {
          "X-CSRF-Token": token,
        }
      }).then(({ data }) => {
        setInitialLayouts(layouts)
        // console.log({saved: layouts })
      });
    } catch (error) {
      console.error(error);
    }
    setIsEditing(false);
  }

  const resetLayout = () => {
    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      axios.post('/user_configuration/dashboard_grid_layout', { layout: { ...LAYOUT } }, {
        headers: {
          "X-CSRF-Token": token,
        }
      }).then(({ data }) => {
      });
    } catch (error) {
      console.error(error);
    }
    setIsEditing(false);
    setLayouts({ ...LAYOUT });
    setInitialLayouts({ ...LAYOUT })
  }

  const onLayoutChange = (layout, layouts) => {
    setLayouts(layouts);
  }




  return (
    <div style={{ paddingLeft: '1.5em' }} ref={containerRef} >
      <div className="settings" >
          {!isEditing &&
        <div className="settings-container">
            <a href="/dashboard">Old Dashboard</a>
            <SettingFilled
              style={{ fontSize: '24px' }}
              onClick={() => { enableEditing() }}
            />
        </div>
          }

        {isEditing &&

          <div className="actions center buttons-container">
            <div className="center-buttons">
              <Button type="primary" onClick={() => openModal(saveLayout, "Are you sure you want to save this layout?")}>
                Save Changes
              </Button>
              <Button type="primary" ghost onClick={() => openModal(cancelEditing, "Are you sure you want to discard this layout?")}>
                Discard Unsaved Changes
              </Button>
            </div>
            <div className="reset-column">
              <Button type="danger" className="reset-button" onClick={() => openModal(resetLayout, "Are you sure you want to reset this layout?")}>
                Reset to Default Layout
              </Button>
            </div>
          </div>

        }
      </div>

      <Responsive
        className="layout"
        measureBeforeMount={false}
        useCSSTransforms={false}
        breakpoints={{ xl: 1400, lg: 1024, md: 800, sm: 600 }}
        cols={{ xl: 16, lg: 16, md: 8, sm: 4 }}
        isResizable={isEditing}
        isDraggable={isEditing}
        layouts={layouts || LAYOUT}
        width={dashboardWidth}
        onLayoutChange={(...params) => {
          onLayoutChange(...params)
        }}
        compacType="horizontal"
        // verticalCompact={false}
        // horizontalCompact={false}
      >
        <div  className={`widget ${isEditing ? 'disable-links ' : ''}`} style={isEditing && { overflow: 'hidden' }} key="a" >
          <div className="sticky-wrapper">
            <CellObservationsWidget />
          </div>
        </div>
        <div  className={`widget ${isEditing ? 'disable-links ' : ''}`} style={isEditing && { overflow: 'hidden' }} key="b" >
          <LiveWidget
            showFilters={ false }
            events_hash={ props.events_hash }
            events={ props.events }
            timezone={props.timezone}
            isWidget={true}
          />
        </div>
        <div className={`widget ${isEditing ? 'disable-links ' : ''}`} style={isEditing && { overflow: 'hidden' }} key="c" >
          <div className="sticky-wrapper">
            <SpecialNeedsWatch />
          </div>
        </div>
        <div className={`widget ${isEditing ? 'disable-links ' : ''}`} style={isEditing && { overflow: 'hidden' }} key="d" >
          <div className="sticky-wrapper">
            <KeepSeparateAlertsWidget />
          </div>
        </div>
        <div  className={`widget ${isEditing ? 'disable-links ' : ''}`} style={isEditing && { overflow: 'hidden' }} key="e" >
          <div className="sticky-wrapper">
            <HeadCountAlertsWidget setModal={ setHeadCountModal } />
          </div>
        </div>
        <div  className={`widget ${isEditing ? 'disable-links ' : ''}`} style={isEditing && { overflow: 'hidden' }} key="f" >
          <div className="sticky-wrapper">
            <MissedDispensation setModal={ setDispensationModal } />
          </div>
        </div>
        <div className={`widget ${ isEditing ? 'disable-links ' : ''}`} style={isEditing && { overflow: 'hidden' }} key="g" >
          <div className="sticky-wrapper">
            <MealPass setModal={ setMealPassModal } />
          </div>
        </div>
      </Responsive>
      {/*...*/}
      <Modal
        title="Confirm Action"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p className="modal-message">{modalMessage}</p>
      </Modal>
      <Modal
        centered
        visible={dispensationModal}
        onCancel={() => setDispensationModal(false)}
        footer={null}
        width={1200}
      >
      <div  id="dispensation-modal" title="Dispensation" className="modal modal-widget">
        <div className="modal-body dispensation-info">
        </div>
        </div>
      </Modal>

      <div id="pause-prescription" title="Pause Prescription">
        <div className="modal-body pause-prescription">
        </div>
      </div>
      <div id="new-prescription" >
      </div>

      <Modal
        centered
        visible={headCountModal}
        onCancel={() => setHeadCountModal(false)}
        footer={null}
        width={1200}
      >
        <div id="head-count-modal" className="modal-widget">
          <div className="modal-body head-count-info">
          </div>
        </div>
      </Modal>

      <Modal
        centered
        visible={mealPassModal}
        onCancel={() => setMealPassModal( false )}
        footer={ null }
        width={ 1200 }
      >
        <div className="modal-widget" id="meals-modal" >
          <div className="modal-body meals-info">
          </div>
        </div>
      </Modal>

      <Modal
        centered
        visible={mealPassCompleteModal}
        onCancel={() => setMealPassCompleteModal( false )}
        footer={ null }
        width={ 1200 }
      >
        <div className="modal-widget" id="meal-complete-modal" >
          <div className="modal-body meal-complete-info">
          </div>
        </div>
      </Modal>

    </div >
  )
};

export default Dashboard;
