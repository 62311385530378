import React from "react"
import CustomRingProgress from './shared/custom-ring'
import CustomBreakdown from './shared/work-chart'
import CustomColumnPlot from "./shared/custom-column-plot"
import { Divider, Space } from 'antd'
import { Statistic, Row, Col } from 'antd';
import { LikeOutlined } from '@ant-design/icons';


//import 'antd/dist/antd.css';

class MetricsDashboard extends React.Component {
  state = {}

  render () {
    return (
      <div className="live-dashboard">
        <Divider style={{ padding: '1em' }}>Quick Stats</Divider>

        <Row gutter={16} style={{ justifyContent: 'center' }}>
          <Col span={4}>
            <Statistic className="center" title="Observations" value={1128} />
          </Col>
          <Col span={4}>
            <Statistic className="center" title="Meal Trays" value={400} suffix="/ 600" />
          </Col>
          <Col span={4}>
            <Statistic className="center" title="Daily Log Entries" value={172} />
          </Col>
          <Col span={4}>
            <Statistic className="center" title="Activities Tracked" value={912} />
          </Col>
          <Col span={4}>
            <Statistic className="center" title="Location Changes" value={243} />
          </Col>
        </Row>

        <Divider style={{ padding: '1em' }}>Last Hour</Divider>

        <div className="shelf" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <CustomRingProgress name='Round Completion' percent={.70} />
          <CustomRingProgress name='Inmates meeting OOC minimums' percent={.45} />
          <CustomRingProgress name='Head Count' percent={1} />
        </div>

        <Divider style={{ padding: '1em' }}>Today</Divider>

        <div className="shelf" style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomBreakdown />

          <Divider type="vertical" style={{ height: '50vh', alignSelf: 'center' }} />

          <CustomColumnPlot />
        </div>

      </div>
    )
  }
}

export default MetricsDashboard
