function extractTimeZoneOffset(dateTimeString) {
  // Extract the timezone part from the string (-05:00 in this case)
  let timeZoneStr = dateTimeString.slice(-6, -3);

  // Convert the timezone string to an integer
  return parseInt(timeZoneStr, 10);
}

const calcTime = function (d, offset) {
  // create Date object for current location
  // d = new Date();

  // convert to msec
  // add local time zone offset
  // get UTC time in msec
  const utc = d.getTime() + (d.getTimezoneOffset() * 60000);

  // create new Date object for different city
  // using supplied offset
  return new Date(utc + (3600000 * offset));
}


export const getTimeDifference = (pastTime) => {
  // get offset from browser
  const offset = extractTimeZoneOffset(pastTime)

  // get current hour adjusted by offset
  var now = calcTime(new Date(), offset)

  const timeZoneStrippedDate = pastTime.slice(0, -6);
  const countFrom = new Date(timeZoneStrippedDate);

  const behind = now > countFrom
  const timeDifference = behind ? (now - countFrom) : (countFrom - now);
  // console.log(countFrom)

  const secondsInADay = 60 * 60 * 1000 * 24
  const secondsInAHour = 60 * 60 * 1000;
let days = Math.floor(timeDifference / (secondsInADay) * 1);
let hours = Math.floor((timeDifference % (secondsInADay)) / (secondsInAHour) * 1);
const minutes = Math.floor(((timeDifference % (secondsInADay)) % (secondsInAHour)) / (60 * 1000) * 1);
const seconds = Math.floor((((timeDifference % (secondsInADay)) % (secondsInAHour)) % (60 * 1000)) / 1000 * 1);

  days = days > 0 ? days : null;
  hours = hours > 0 || (days !== null && hours === 0) ? hours : null;

  return { days, hours, minutes, seconds };
};
