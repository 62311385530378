import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Editor, Viewer } from '@toast-ui/react-editor';

class FormBuilder extends React.Component {
  state = {
    form_category_error: "",
    editing: false,
    isSaving: false,
    hasFacilities: false,
    users: [],
    form_types: [],
    form_categories: [],
    form: {
      is_new: true,
      id: null,
      name: '',
      send_notifications: false,
      requires_privacy: false,
      contacts: [],
      form_type: { value: 'standard', label: 'Standard' },
      form_category: null,
      questions: [
        {
          id: null,
          tempId: `temp-${Math.random().toString(36).slice(2, 9)}`,
          text: '',
          type: 'TextQuestion',
          removable: true,
          options: [],
        },
        {
          id: null,
          tempId: `temp-${Math.random().toString(36).slice(2, 9)}`,
          text: 'Does this form require Follow Up?',
          type: 'AttentionRequiredQuestion',
          removable: false,
          required: true,
          generated: true,
          options: [
            { id: null, name: 'Yes' },
            { id: null, name: 'No' },
          ],
        },
      ],
    },
  };

  componentDidMount() {
    this.fetchFormCategories().then(() => {
      const formCategoryId = this.props.formCategoryId;

      const selectedCategory = this.state.form_categories.find(c => c.value === formCategoryId);

      if (selectedCategory) {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            form_category: selectedCategory
          }
        }));
      }
    })
  }


  fetchFormCategories() {
    return axios.get('/form_categories.json')
      .then(response => {
        const procesed_categories = response.data.map(category => ({ value: category.id, label: category.name }));
        this.setState({ form_categories: procesed_categories });
      })
      .catch(error => {
        console.log(error);
      });
  }


  constructor(props) {
    super(props);
    // this.editorRef = React.createRef();
    this.editorRefs = [];
    if (props.form) this.state.form = props.form;
    if (props.editing) this.state.editing = props.editing;
    if (props.hasFacilities) this.state.hasFacilities = props.hasFacilities;
    if (props.users) this.state.users = props.users;
    if (props.form_types) this.state.form_types = props.form_types;
    if (props.form_categories) this.state.form_categories = props.form_categories;

    // Setup initial value of contact search
    if (this.state.form.contacts.length) {
      const contact = this.state.form.contacts[0];
      const emailString = contact.email ? contact.email : 'Email not setup';
      this.state.contactSearchValue = `${contact.full_name} (${emailString})`;
    }

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.renderQuestions = this.renderQuestions.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.renderFormCategorySection = this.renderFormCategorySection.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.moveQuestionUp = this.moveQuestionUp.bind(this);
    this.moveQuestionDown = this.moveQuestionDown.bind(this);
    this.cycleEditing = this.cycleEditing.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.renderContactSection = this.renderContactSection.bind(this);
    this.handleNotificationChange = this.handleNotificationChange.bind(this);
    this.renderRequiresPrivacySection =
      this.renderRequiresPrivacySection.bind(this);
    this.handleRequiresPrivacyChange =
      this.handleRequiresPrivacyChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.renderFormTypeSection = this.renderFormTypeSection.bind(this);
    this.handleFormTypeChange = this.handleFormTypeChange.bind(this);
    this.handleFormCategoryChange = this.handleFormCategoryChange.bind(this);
  }
  generateTempId = () => `temp-${Math.random().toString(36).slice(2, 9)}`;
  handleCancel() {
    window.location.href = '/forms';
  }

  handleSave() {
    const { form } = this.state;
    const { id, questions, contacts, is_new, form_category } = form;

    // Verifica si se ha seleccionado una categoría de formulario
    if (!form_category || !form_category.value) {
      // Actualiza el estado para mostrar el mensaje de error
      this.setState({ form_category_error: "Please select a form category." });
      return; // Detiene la ejecución si no se selecciona la categoría
    } else {
      // Limpia el mensaje de error si el formulario está correcto
      this.setState({ form_category_error: "" });
    }

    this.setState({ isSaving: true });

    // Normaliza las preguntas para manejar las posiciones
    let index = 0;
    const newQuestions = questions.map((question) => {
      const q = {
        ...question,
        position: index,
        options: question.options.map((option, idx) => ({
          ...option,
          position: idx,
        })),
      };
      if (!question._destroy) {
        index += 1;
      }
      return q;
    });

    // Prepara los atributos de usuarios para los formularios
    let forms_users_attributes;
    if (contacts) {
      forms_users_attributes = contacts.map((user) => ({
        user_id: user.value,
      }));
    }

    const processedQuestions = newQuestions.map((question) => {
      const { tempId, ...restOfQuestion } = question;
      return restOfQuestion;
    });

    // Prepara el objeto de datos para enviar al servidor
    const data = {
      ...this.state.form,
      form_type: this.state.form.form_type.value,
      form_category_id: this.state.form.form_category.value,
      questions_attributes: processedQuestions.map((question) => ({
        ...question,
        question_options_attributes: question.options,
      })),
      forms_users_attributes,
    };

    // Realiza la petición POST o PUT según si el formulario es nuevo o está siendo editado
    if (id == null) {
      axios.post(`/forms`, { form: data })
        .then(({ data }) => {
          this.setState({ form: data.form, isSaving: false });
          if (data.status === 200) {
            window.location.href = '/forms';
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({ isSaving: false });
        });
    } else {
      axios.put(`/forms/${id}`, { form: data })
        .then(({ data }) => {
          this.setState({ form: data.form, isSaving: false });
          if (data.status === 200) {
            window.location.href = '/forms';
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({ isSaving: false });
        });
    }
  }


  renderQuestions(questions) {
    return (
      <ul className='questions'>
        {questions.map((question, index) => (
          <li key={question.tempId || question.id}>
            {this.renderQuestion(question, index)}
          </li>
        ))}
      </ul>
    );
  }

  renderQuestion(question, i) {
    const { type, text, options } = question;
    const {
      editing,
      hasFacilities,
      form: { questions },
    } = this.state;

    const index = questions.indexOf(question);
    if (!this.editorRefs[index]) {
      this.editorRefs[index] = React.createRef();
    }

    return (
      <div className='question row'>
        <div className='add-question col s12 center-align'>
          {editing && (
            <button
              className='btn'
              onClick={() => {
                this.addQuestion(index);
              }}
            >
              <span className='add-question-text'>
                <i className='material-icons left'>add</i>
                Add Question Here
              </span>
            </button>
          )}
        </div>
        <div className='col s12'>
          <div
            className={`card ${
              question.errors && Object.keys(question.errors).length
                ? 'card-invalid'
                : ''
            }`}
          >
            <span className='question-number center-align'>{i + 1}</span>
            <div className='card-content'>
              <div className='row valign-wrapper'>
                <div className='col s2 right-align'>
                  <span>Question:</span>
                </div>
                <div className='col s10'>
                  {!editing ||
                  index == questions.length - 1 ||
                  question.generated ? (
                    <div
                      className='input-field inline'
                      style={{
                        marginTop: '0',
                      }}
                    >
                      <Viewer initialValue={text} />
                    </div>
                  ) : (
                    <div
                      className='input-field inline'
                      style={{
                        marginTop: '0',
                        resize: 'vertical',
                        overflowY: 'hidden',
                        height: '100px',
                      }}
                    >
                        <Editor
                        autofocus={false}
                        previewStyle='vertical'
                        height='100%'
                        initialEditType='wysiwyg'
                        initialValue={text}
                        usageStatistics={false}
                        hideModeSwitch={true}
                        onChange={() => {
                          const content = this.editorRefs[index].current
                            .getInstance()
                            .getMarkdown();
                          this.handleChange(
                            {
                              target: { value: content },
                            },
                            index
                          );
                        }}
                        ref={this.editorRefs[index]}
                        toolbarItems={[
                          ['heading', 'bold', 'italic', 'strike'],
                          ['hr'],
                          ['ul', 'ol'],
                          ['table'],
                          ['scrollSync'],
                        ]}
                      />
                    </div>
                  )}
                  {question.errors && (
                    <span className='invalid'>{question.errors.text}</span>
                  )}
                </div>
              </div>
              <div className='row valign-wrapper'>
                <div className='col s2 right-align'>
                  <span>Question Type:</span>
                </div>
                <div className='col s10'>
                  <select
                    type='select'
                    disabled={
                      !editing ||
                      index == questions.length - 1 ||
                      question.generated
                    }
                    value={type}
                    onChange={(event) => {
                      this.handleDropdown(event, index);
                    }}
                    className='browser-default'
                  >
                    <option value=''>Choose Question Type</option>
                    {question.type == 'DisciplinaryReasonQuestion' && (
                      <option value='DisciplinaryReasonQuestion'>Text</option>
                    )}
                    <option value='TextQuestion'>Text</option>
                    <option value='YesNoQuestion'>Yes or No</option>
                    <option value='SelectQuestion'>Single Answer</option>
                    <option value='MultiSelectQuestion'>Multi Answer</option>
                    <option value='NoteQuestion'>Note</option>
                    <option value='SingleInmateQuestion'>Single Inmate Related</option>
                    <option value='InmateQuestion'>Multiple Inmate Related</option>
                    <option value='ActivityQuestion'>Activity Related</option>
                    <option value='CellQuestion'>Cell Related</option>
                    {hasFacilities && (
                      <option value='FacilityQuestion'>Facility Related</option>
                    )}
                    <option value='StaffQuestion'>Staff Related</option>
                    <option value='SignatureQuestion'>Signature</option>
                  </select>
                </div>
              </div>

              {['SelectQuestion', 'MultiSelectQuestion'].includes(type) &&
                this.renderOptions(
                  options.filter((option) => !option._destroy),
                  index
                )}
            </div>
            {editing && index !== questions.length - 1 && (
              <div className='card-action question-actions valign-wrapper'>
                <div className='col m8 move-actions'>
                  {index > 0 && (
                    <button
                      className='btn'
                      onClick={() => {
                        this.moveQuestionUp(index);
                      }}
                    >
                      <i className='material-icons left'>arrow_upward</i>
                      Move this question up
                    </button>
                  )}
                  {index < questions.length - 2 && (
                    <button
                      className='btn'
                      onClick={() => {
                        this.moveQuestionDown(index);
                      }}
                    >
                      <i className='material-icons left'>arrow_downward</i>
                      Move this question down
                    </button>
                  )}
                </div>
                {questions.length > 1 && question.removable && (
                  <div className='col m4 right-align'>
                    <button
                      className='btn btn-small remove-question text-lighten-2'
                      onClick={() => {
                        this.removeQuestion(index);
                      }}
                    >
                      Remove This Question
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderOptions(options, q_index) {
    const { editing } = this.state;

    return (
      <div>
        <div className='row valign-wrapper'>
          <div className='col s2 right-align'>
            <span>Options:</span>
          </div>
          <div className='col s10'></div>
        </div>
        <div className='row'>
          <div className='col s2'></div>
          <div className='col s10'>
            <ul>
              {options.map((option, index) => {
                return this.renderOption(option, index, q_index);
              })}
            </ul>
            {editing && (
              <button
                className='btn btn-small add-option'
                onClick={() => {
                  this.addOption(q_index);
                }}
              >
                <i className='material-icons left'>add</i>
                Add Option
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderOption(option, index, q_index) {
    const { editing } = this.state;
    const options = this.state.form.questions[q_index].options;

    return (
      <li className='option' key={index}>
        <div className='row valign-wrapper'>
          <div className='col s6'>
            <input
              type='text'
              placeholder='Option Text'
              disabled={!editing}
              value={option.name}
              onChange={(event) => {
                this.handleOptionChange(event, q_index, index);
              }}
            ></input>
            {option.errors && option.errors.name && (
              <span className='invalid'>{option.errors.name}</span>
            )}
          </div>
          <div className='col s6'>
            {editing && options.length > 2 && (
              <button
                className='btn btn-small remove-option text-lighten-2'
                onClick={() => {
                  this.removeOption(index, q_index);
                }}
              >
                Remove This Option
              </button>
            )}
          </div>
        </div>
      </li>
    );
  }

  handleNameChange(event) {
    this.setState({
      form: {
        ...this.state.form,
        name: event.target.value,
      },
    });
  }

  moveQuestionUp(index) {
    if (index <= 0) return;

    let questions = [...this.state.form.questions];

    let temp = questions[index - 1];
    questions[index - 1] = questions[index];
    questions[index] = temp;

    this.setState({
      form: { ...this.state.form, questions },
    });
  }

  moveQuestionDown(index) {
    if (index >= this.state.form.questions.length - 1) return;

    let questions = [...this.state.form.questions];

    let temp = questions[index + 1];
    questions[index + 1] = questions[index];
    questions[index] = temp;

    this.setState({
      form: { ...this.state.form, questions },
    });
  }

  cycleEditing() {
    this.setState({ editing: !this.state.editing });
  }

  handleChange(event, index) {
    this.setState({
      form: {
        ...this.state.form,
        questions: this.state.form.questions.map((question, idx) => {
          return index == idx
            ? Object.assign({}, question, { text: event.target.value })
            : question;
        }),
      },
    });
  }

  handleOptionChange(event, q_index, index) {
    this.setState({
      form: {
        ...this.state.form,
        questions: this.state.form.questions.map((question, idx) => {
          if (q_index == idx) {
            return {
              ...question,
              options: question.options.map((option, _idx) => {
                return index == _idx
                  ? { ...option, name: event.target.value }
                  : option;
              }),
            };
          } else {
            return question;
          }
        }),
      },
    });
  }

  handleDropdown(event, index) {
    this.setState({
      form: {
        ...this.state.form,
        questions: this.state.form.questions.map((question, idx) => {
          if (index == idx) {
            let newQuestion = Object.assign({}, question, {
              type: event.target.value,
            });
            if (['YesNoQuestion'].includes(event.target.value)) {
              newQuestion.options = [
                { id: null, name: 'Yes' },
                { id: null, name: 'No' },
              ];
            }
            if (
              ['SelectQuestion', 'MultiSelectQuestion'].includes(
                event.target.value
              )
            ) {
              if (
                typeof question.options == 'undefined' ||
                question.options.length == 0
              )
                newQuestion.options = [
                  { id: null, name: '' },
                  { id: null, name: '' },
                ];
            }
            return newQuestion;
          } else {
            return question;
          }
        }),
      },
    });
  }

  addOption(index) {
    this.setState({
      form: {
        ...this.state.form,
        questions: this.state.form.questions.map((question, idx) => {
          return index == idx
            ? Object.assign({}, question, {
                options: [...question.options, { id: null, name: '' }],
              })
            : question;
        }),
      },
    });
  }

  removeOption(index, q_index) {
    this.setState({
      form: {
        ...this.state.form,
        questions: this.state.form.questions.map((question, idx) => {
          return q_index == idx
            ? {
                ...question,
                options: question.options.map((option, _idx) => {
                  if (index == _idx) {
                    return { ...option, _destroy: true };
                  } else {
                    return option;
                  }
                }),
              }
            : question;
        }),
      },
    });
  }

  addQuestion(index) {
    const newQuestions = this.state.form.questions;
    newQuestions.splice(index, 0, {
      id: null,
      tempId: `temp-${Math.random().toString(36).slice(2, 9)}`,
      text: '',
      type: 'TextQuestion',
      removable: true,
      options: [],
    });

    this.setState({
      form: {
        ...this.state.form,
        questions: newQuestions,
      },
    });
  }

  removeQuestion(index) {
    this.setState({
      form: {
        ...this.state.form,
        questions: this.state.form.questions.map((question, idx) => {
          if (index == idx) {
            return { ...question, _destroy: true };
          } else {
            return question;
          }
        }),
      },
    });
  }

  handleNotificationChange({ target: { checked } }) {
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        send_notifications: checked,
      },
    });
  }

  handleSelectChange(contacts) {
    this.setState({
      form: {
        ...this.state.form,
        contacts,
      },
    });
  }

  renderContactSection() {
    const {
      editing,
      users,
      form: { contacts, send_notifications },
    } = this.state;

    return (
      <div>
        <div className='row valign-wrapper'>
          <div className='col s3'></div>
          <div className='col s9'>
            <input
              className='reset-checkbox'
              onChange={this.handleNotificationChange}
              type='checkbox'
              id='notifications'
              name='notifications'
              checked={send_notifications}
              disabled={!editing}
            ></input>
            <label htmlFor='notifications'>
              Send notifications when form requires attention
            </label>
          </div>
        </div>
        {send_notifications && (
          <div className='row valign-wrapper'>
            <div className='col s3'>
              <h2 className='right' style={{ margin: 0 }}>
                Contact:
              </h2>
            </div>
            <div className='col s8'>
              <Select
                className='contact'
                value={contacts}
                onChange={this.handleSelectChange}
                isMulti={true}
                options={users}
              />
            </div>
            <div className='col s1'></div>
          </div>
        )}
      </div>
    );
  }

  renderFormTypeSection() {
    const {
      form_types,
      form: { form_type },
    } = this.state;

    return (
      <div className='row valign-wrapper'>
        <div className='col s3'>
          <h2 className='right' style={{ margin: 0 }}>
            Form Type:
          </h2>
        </div>
        <div className='col s9'>
          <Select
            value={form_type}
            onChange={this.handleFormTypeChange}
            className='form-type'
            options={form_types}
          />
        </div>
      </div>
    );
  }

  renderFormCategorySection() {
    const {
      editing,
      form_categories,
      form: { form_category },
      form_category_error
    } = this.state;

    const selectStyle = form_category_error ? { borderColor: 'red' } : {};

    return (
      <div className='row valign-wrapper'>
        <div className='col s3'>
          <h2 className='right' style={{ margin: 0 }}>
            Form Category:
          </h2>
        </div>
        <div className='col s9'>
          <Select
            value={form_category}
            onChange={this.handleFormCategoryChange}
            className='form-type'
            options={form_categories}
            placeholder="Select category"
            isDisabled={!editing}
            styles={{ control: (base) => ({ ...base, ...selectStyle }) }}
          />
          {form_category_error && (
            <div style={{ color: 'red', marginTop: '5px' }}>{form_category_error}</div>
          )}
        </div>
      </div>
    );
  }



  handleFormCategoryChange(selectedOption) {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        form_category: selectedOption,
      },
    }));
  }



  handleFormTypeChange(form_type) {
    const {
      form,
      form: { questions },
    } = this.state;

    // Only keep non generated questions
    // Return non-generated question and questions that have an id
    //  if a non-generated question has an id, we need to set _destroy: true
    let filteredQuestions = questions.filter((question) => {
      return !question.generated || question.id;
    });

    filteredQuestions = filteredQuestions.map((question) => {
      if (question.id && question.generated) {
        return { ...question, _destroy: true };
      } else {
        return question;
      }
    });

    let additionalQuestions = [];

    if (form_type.value == 'disciplinary') {
      additionalQuestions = [
        {
          id: null,
          tempId: `temp-${Math.random().toString(36).slice(2, 9)}`,
          text: 'Inmates involved?',
          type: 'InmateQuestion',
          removable: false,
          required: true,
          generated: true,
          options: [],
        },
        {
          id: null,
          tempId: `temp-${Math.random().toString(36).slice(2, 9)}`,
          text: 'Reason for disciplinary action? (This is what will be listed in inmate manager disciplinary file)',
          type: 'DisciplinaryReasonQuestion',
          removable: false,
          required: true,
          generated: true,
          options: [],
        },
        {
          id: null,
          tempId: `temp-${Math.random().toString(36).slice(2, 9)}`,
          text: 'Does this form require Follow Up?',
          type: 'AttentionRequiredQuestion',
          removable: false,
          required: true,
          generated: true,
          options: [
            { id: null, name: 'Yes' },
            { id: null, name: 'No' },
          ],
        },
      ];
    } else {
      additionalQuestions = [
        {
          id: null,
          tempId: `temp-${Math.random().toString(36).slice(2, 9)}`,
          text: 'Does this form require Follow Up?',
          type: 'AttentionRequiredQuestion',
          removable: false,
          required: true,
          generated: true,
          options: [
            { id: null, name: 'Yes' },
            { id: null, name: 'No' },
          ],
        },
      ];
    }

    this.setState({
      form: {
        ...form,
        form_type: form_type,
        questions: [...filteredQuestions, ...additionalQuestions],
      },
    });
  }

  renderRequiresPrivacySection() {
    const {
      editing,
      form: { requires_privacy },
    } = this.state;

    return (
      <div className='row valign-wrapper'>
        <div className='col s3'></div>
        <div className='col s9'>
          <input
            className='reset-checkbox'
            onChange={this.handleRequiresPrivacyChange}
            type='checkbox'
            id='requires-privacy'
            name='requires-privacy'
            checked={requires_privacy}
            disabled={!editing}
          ></input>
          <label htmlFor='requires-privacy'>
            Privacy required (Requires supervisor role to view answers)
          </label>
        </div>
      </div>
    );
  }

  handleRequiresPrivacyChange(event) {
    this.setState({
      form: {
        ...this.state.form,
        requires_privacy: event.target.checked,
      },
    });
  }

  render() {
    const {
      form: { errors, name, questions },
      editing,
      isSaving,
    } = this.state;

    return (
      <div className='form form-builder' >
        <div className='row valign-wrapper'>
          <div className='col s3'>
            <h2 className='right'>Form Name:</h2>
          </div>
          <div className='col s8'>
            <input
              type='text'
              disabled={!editing}
              placeholder='Form Name'
              onChange={this.handleNameChange}
              value={name}
            />
            {errors && errors.name && errors.name.length && (
              <span className='invalid'>{errors.name}</span>
            )}
          </div>
          <div className='col s1 right-align'>
            {!editing && (
              <button className='btn edit-action' onClick={this.cycleEditing}>
                Edit
              </button>
            )}
          </div>
        </div>

        {this.renderFormTypeSection()}

        {this.renderFormCategorySection()}

        {this.renderRequiresPrivacySection()}

        {this.renderContactSection()}

        {this.renderQuestions(
          questions.filter(
            (q) => !q._destroy && q.type !== 'AttentionRequiredQuestion'
          )
        )}

        {editing && (
          <div className='add-question col s12 center-align'>
            <button
              className='btn'
              onClick={() => {
                this.addQuestion(questions.length - 1);
              }}
            >
              <span className='add-question-text'>
                <i className='material-icons left'>add</i>
                Add Question Here
              </span>
            </button>
          </div>
        )}

        {editing && (
          <div className='form-actions row'>
            <div className='col s12 right-align'>
              <button
                className='btn btn-large cancel'
                onClick={this.handleCancel}
              >
                Cancel
              </button>
              <button
                className='btn btn-large save'
                disabled={isSaving}
                onClick={this.handleSave}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FormBuilder;
