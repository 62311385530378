import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getTimeDifference } from '../utils/past_due_timer';

const TimeTag = ({ time, inmate_not_present = false, location_name, color }) => {
  const elementRef = useRef(null);
  if (inmate_not_present) {
    return <p style={{ marginBottom: "0" }}>{ location_name }</p>;
  }
  if (time === "Not Yet Observed") {
    return <p style={{ marginBottom: "0" }}>{time}</p>;
  }
  const [currentColor, setCurrentColor] = useState("yellow");
  const [displayedTime, setDisplayedTime] = useState(time);
  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = getTimeDifference(time);
      setDisplayedTime(newTime);

    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
      const red = displayedTime?.days === null &&
        displayedTime?.hours === null &&
        displayedTime?.minutes === 0 &&
        displayedTime?.seconds <= 59;

    if (red) {
      setCurrentColor("red");
    }
    else {
      setCurrentColor("yellow");
    }
  }, [displayedTime, time]);

  useEffect(() => {
    if (elementRef.current && elementRef.current.parentNode && color==="yellow") {
      const parentElement = elementRef.current.parentNode.parentNode;

      if (currentColor === "red" && parentElement.classList.contains("past-due-yellow")) {
        parentElement.classList.remove("past-due-yellow");
        parentElement.classList.add("past-due-red");
      }
      if (currentColor === "yellow" && parentElement.classList.contains("past-due-red")) {
        parentElement.classList.remove("past-due-red");
        parentElement.classList.add("past-due-yellow");
      }
    }
  }, [currentColor, displayedTime]);




  return (
    <p style={{ marginBottom: "0" }}
      ref={elementRef} >
      {displayedTime?.minutes !== undefined && displayedTime?.seconds !== undefined
        ? `${displayedTime.days ? displayedTime.days + 'd ' : ''}${displayedTime.hours ? displayedTime.hours + 'h ' : ''}${displayedTime.minutes}m ${displayedTime.seconds}s`
        : '-'
      }
    </p>
  );
};

export default TimeTag;
